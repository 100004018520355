@charset "UTF-8";

@import "partials/foundation-settings";

/*=======================================
				Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

// Foundation 5.5.2
@import 'vendor/foundation/grid';
@import 'vendor/foundation/accordion';
@import 'vendor/foundation/alert-boxes';
@import 'vendor/foundation/block-grid';
// @import 'vendor/foundation/breadcrumbs';
@import 'vendor/foundation/button-groups';
@import 'vendor/foundation/buttons';
@import 'vendor/foundation/clearing';
@import 'vendor/foundation/dropdown';
@import 'vendor/foundation/dropdown-buttons';
// @import 'vendor/foundation/flex-video';
@import 'vendor/foundation/forms';
// @import 'vendor/foundation/icon-bar';
// @import 'vendor/foundation/inline-lists';
// @import 'vendor/foundation/joyride';
// @import 'vendor/foundation/keystrokes';
@import 'vendor/foundation/labels';
// @import 'vendor/foundation/magellan';
// @import 'vendor/foundation/orbit';
@import 'vendor/foundation/pagination';
@import 'vendor/foundation/panels';
// @import 'vendor/foundation/pricing-tables';
// @import 'vendor/foundation/progress-bars';
// @import 'vendor/foundation/range-slider';
// @import 'vendor/foundation/reveal';
@import 'vendor/foundation/side-nav';
// @import 'vendor/foundation/split-buttons';
// @import 'vendor/foundation/sub-nav';
// @import 'vendor/foundation/switches';
@import 'vendor/foundation/tables';
// @import 'vendor/foundation/tabs';
@import 'vendor/foundation/thumbs';
// @import 'vendor/foundation/tooltips';
@import 'vendor/foundation/top-bar';
@import 'vendor/foundation/type';
@import 'vendor/foundation/offcanvas';
@import 'vendor/foundation/visibility';

@import "partials/fonts";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";

// Site Styles
@import "partials/base";
@import "partials/layouts";
@import "partials/account";
@import "partials/header";
@import "partials/coupons";
@import "partials/articles";
@import "partials/blog-grid";
@import "partials/form-controls";
@import "partials/banner";
// @import "partials/banner-with-nav";
@import "partials/sub-banner";
@import "partials/subnav";
@import "partials/events";
// @import "partials/offcanvas-hlm";
// @import "partials/callout-bar";
// @import "partials/contact-box";
// @import "partials/callout-links";
// @import "partials/callout-blocks";
// @import "partials/testimonials";
// @import "partials/homepage-callout";
@import "partials/social-links";
// @import "partials/footer-callout";
@import "partials/page-home";
@import "partials/products";
@import "partials/footer";

/*=======================================
			Clearing Gallery
=======================================*/

.clearing-close{
	text-decoration: none;
}

.carousel{
	margin-top: 0 !important;
}

/*=======================================
			Flex Slider
=======================================*/

.slides{
	@include clearfix;
	padding:0;
	margin:0;
}

/*=======================================
			Sub Navigation
=======================================*/

.sub-nav {
	display: block;
	width: auto;
	overflow: hidden;
	margin: -0.22222rem 0 1rem;
	padding-top: 0.22222rem;
	margin-right: 0;
	margin-left: -0.66667rem;
	background-color: #f2f2f2;
	padding: 10px 10px 10px 0;
	a{
		text-decoration:none;
		padding:0 20px;
		display:block;
		float:left;
	}
	.active{
		a{
			background:#008CBA;
			color:#fff;
		}
	}
}

/*=======================================
			SML Settings
=======================================*/

.sml{

	h4{
		color: #fff;
		font-size: rem-calc(24);
	}

	ul{}

	li{
		color: #fff;
	}

	a{
		color: inherit;
	}
}

/*=======================================
			Site Styles
=======================================*/

.brands{
	padding-top: rem-calc(30);
	text-align: center;

	border-bottom: 1px solid rgba(#333, .4);

	[class*="block-grid-"] {
		margin: 0;
	}

	.logo-grid {
		margin-bottom: rem-calc(30);
	}

	@media #{$large-up}{
		padding: rem-calc(80 0 30);
	}
}

.best-sellers {
	padding: rem-calc(30 0);
	background: #EFEFEF;
	text-align: center;
	
	li {
		background: #fff;
		position: relative;
		padding-bottom: 0;
		&::after {
			content: '';
			position: absolute;
			right: 0;
			border-left: 1px dashed #c7c7c7;
			height: rem-calc(80);
			top: rem-calc(23);
		}
		&:last-child::after {
			content: none;
		}
		a {
			display: table;
			width: 100%;
			text-align: center;
			padding-top: rem-calc(30);
			padding-bottom: rem-calc(30);
		}
		div {
			display: table-cell;
			vertical-align: middle;
		}
	}
	.featured-products-link {
		color: #80C245;
		font-size: rem-calc(22);
		margin-bottom: rem-calc(10);
		display: inline-block;
	}
	.heading {
		@extend h1;
		margin-bottom: 0;
	}
	@media #{$large-up}{
		padding: rem-calc(60 0);
		.heading {
			display: inline-block;
		}
		.featured-products-link {
			margin-top: rem-calc(10);
		}
	}
}

// Responsive Youtube Embeds
.featured-lecture {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
    margin-bottom: rem-calc(20);
}

.featured-lecture iframe,
.featured-lecture object,
.featured-lecture embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
// End Responsive Youtube Embeds

.meet-docs {
	.heading {
		@extend h1;
	}
}

.sidebar-callout {
	padding: rem-calc(30);
	margin: 0 auto rem-calc(30);
	overflow: hidden;
	max-width: rem-calc(290);
	.heading {
		font-size: rem-calc(24);
		color: #fff;
		background: rgba(0,0,0,.77);
		margin: rem-calc(0 0 15 -30);
		padding: rem-calc(10 0 10 30);
		font-weight: 700;
		min-width: rem-calc(250);
	}
	p {
		color: #fff;
		font-size: rem-calc(16);
		font-family: $special-font-family;
		text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.75);
	}
	.quote {
		color: #fff;
		line-height: 1.4;
		font-size: rem-calc(16);
		font-weight: 700;
		text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.75);
		font-family: $special-font-family;
		margin-bottom: rem-calc(20);
	}
	.button {
		margin-bottom: 0;
	}
	&.sidebar-supplements {
		background: url('/images/sidebar-callout-supplements.png');
	}
	&.sidebar-treatment {
		background: url('/images/sidebar-callout-treatment.png');
	}
	&.sidebar-advisers {
		background: url('/images/sidebar-callout-advisers.png');
		.heading {
			margin-bottom: rem-calc(100);
		}
	}
}

.services-page {
	text-align: center;
	@media #{$large-up}{
		text-align: left;
		padding: rem-calc(0 0 50 0);
		h1 {
			margin: rem-calc(0 0 20);
		}
	}
}
.service-callout-container{
	li{
		padding: rem-calc(0 10 20 10);
		@media #{$large-up}{
			padding: rem-calc(0 5 20 5);
		}
		@media #{$xlarge-up}{
			padding: rem-calc(0 10 20 10);
		}
	}
}
.service-callout {
	text-decoration: none;
	border: 1px solid #fff;
	margin-bottom: rem-calc(20);
	display: inline-block;

	.service-title {
		font-family: $special-font-family;
		text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
		font-weight: 700;
		font-size: rem-calc(18);
		background: #84c042;
		border: 1px solid #9be14e;
		color: #fff;
		text-align: center;
		padding: rem-calc(20 0 20 0);
		transition: .2s;
		@media #{$large-up}{
			font-size: rem-calc(16);
		}
		@media #{$xlarge-up}{
			font-size: rem-calc(18);
		}
	}
	&:hover .service-title {
		background: darken(#84c042, 10%);
	}
}

.supplement-callout {
	text-decoration: none;
	border: 1px solid #fff;
	margin-bottom: rem-calc(20);
	display: block;
	box-shadow: 4px 7px 5px 0px rgba(0, 0, 0, 0.17);
	.supplement-title {
		font-family: $special-font-family;
		text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
		font-weight: 700;
		background: #84c042;
		border: 1px solid #a9d476;
		color: #fff;
		text-align: center;
		padding: rem-calc(20);
		transition: .2s;
		img {
			margin-left: rem-calc(6);
		}
	}
	.logo-cont {
		display: flex;
		height: rem-calc(150);
		background: #efefef;
		text-align: center;
		align-items: center;
  		justify-content: center;
  		overflow: hidden;
	}
	&:hover .supplement-title {
		background: darken(#84c042, 10%);
	}
}

.patient-notice {
	text-align: center;
	background: #fff;
	border: rem-calc(3) solid #af5e0f;
	padding: rem-calc(20);
	margin-bottom: rem-calc(30);
	.heading {
		color: #af5e0f;
		font-size: rem-calc(24);
		margin-bottom: rem-calc(20);
	}
	.button {
		margin: rem-calc(0 5 20);
		background: #af5e0f;
		display: block;
		font-size: rem-calc(18);
		img {
			margin-left: rem-calc(3);
			margin-top: rem-calc(-2)
		}
		@media #{$medium-up} {
			display: inline-block;
			margin: rem-calc(0 5);
		}
	}
}

.weight-problem {
	display: none;
	background: url('/images/weight-problem-bg.png') center left no-repeat;
	background-size: cover;
	padding: rem-calc(30 30 30 260);
	margin: rem-calc(30 15 0);
	border: 1px solid #fff;
	box-shadow: 4px 7px 5px 0px rgba(0,0,0,0.17);
	@media #{$medium-up}{
		display: block;
	}
	p {
		font-size: rem-calc(20);
		@media #{$large-up}{
			font-size: rem-calc(24);
		}
	}
	.button {
		text-shadow: none;
	}
}

.accordion-click{
	color:#fff;
	h3 {
		margin: 0;
		transition: .2s;
		border: 1px solid #ccc;
		&:hover {
			cursor: pointer;
			background: #4c5b05;
		}
	}
	&:before{
		font-family:$icon-font;
		content:$icon-caret-right;
		float: right;
		margin-top: rem-calc(4);
		padding: rem-calc(7);
		transition: .2s;
	}
}
.accordion-heading {
	padding: rem-calc(5);
	transition: .2s;
	border: 1px solid #ccc;
	&:hover {
		cursor: pointer;
		background: #ccc;
	}
}
.accordion-slide {
	padding: rem-calc(5);
}
.arrow-turn{
	.accordion-click{
		color: #fff;
		font-weight: bold;
		&:before{
			font-family:$icon-font;
			transform: rotate(90deg);
		}
	}
}

.fm-qt-SHIPPING_ZIP,
.fm-qt-ZIP{
	.fm-zip{
		width: 100%;
		max-width: rem-calc(420);
	}

	.zip-dash{
		display: none !important;
	}
}


// Lab Testing Page
.lab{
	margin-bottom: 0;
	min-width: 100%;
}
.lab-test{
	margin-bottom: rem-calc(10);
	padding:rem-calc(10 10 10 10);
	text-align: center;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.33);
	border: 1px solid #9be14e;
	border-top: 0;
	color: #fff;
	background-color: #84c042;
	//background-image: linear-gradient(0deg, rgba(0,0,0,0.09) 0%, rgba(255,255,255,0.09) 100%);
	a{
		color: #fff;
	}
}
