// Sort Products Container
form .product_sort_dropdown_container {
	label {
		display: inline-block;
		vertical-align: middle;
		margin-right: rem-calc(10);
		line-height: 33px;
		font-size: rem-calc(18);
		font-weight: 600;
		color: #000;
	}
	input[type="submit"].buttons {
		display: none;
	}
	#SortBox {
		max-width: rem-calc(300);
		display: inline-block;
		vertical-align: middle;
		margin-bottom: rem-calc(20);
	}
}

// List Products 
.products-list {
	a {
		text-decoration: none;
		margin-bottom: rem-calc(20);
		display: block;
	}

	.product_result_container {
		max-width: rem-calc(280);
		text-align: center;
		margin: 0 auto rem-calc(30);
		img {
			display: block;
			width: 100%;
			margin: rem-calc(0 0 10);
		}

		.product_header {
			color: #333;
			font-size: rem-calc(22);
			font-weight: 500;
			line-height: 1.2;
			font-family: $header-font-family;
		}

		.product_cost {
			margin-top: 0;
		}

		.product_cost_view {
			color: #80c245;
			font-size: rem-calc(28);
			font-weight: 700;
		}

		@media #{$large-up}{
			margin-bottom: 0;
		}

		// Cancels out include.css.php styles associated with products
		.product_imagecontainer {
			min-height: 0;
			display: block;
			vertical-align: initial;
		}
		.product_cost_box_standard {
			background: transparent;
			border: 0;
		}
		.product_cost {
			height: auto;
		}
	}
}

// Global MYCE Pagination Style
.paged_results {
	@include grid-column(12);
	margin-bottom: rem-calc(20);
	font-weight: 600;
	.page_selected {
		background: #80c245;
		color: #fff;
		padding: rem-calc(0 3);
	}
	.page_notselected {
		text-decoration: none;
	}
	.see-all-products {
		margin-left: rem-calc(15);
		text-decoration: none;
	}
}

// Product Detail Page
.product-img-container {
	background: #fff;
	margin: rem-calc(0 0 20 0);
    text-align: center;
    border: rem-calc(6) solid #fff;
    box-shadow: inset 0 0 9px 1px #f4f4f4,0px 1px 1px 0px rgba(0,0,0,0.22);
    li {
    	padding-bottom: rem-calc(0);
    }
    @media #{$large-up}{
    	margin-right: rem-calc(30);
    }
}
.product-name {
	@extend h1;
	margin-bottom: rem-calc(0);
}
.product-details-header {
	@extend h1;
}
.product-price {
	font-weight: 700;
	color: #84c042;
	font-size: rem-calc(24);
	margin-bottom: rem-calc(10);
}
.product-list-description {
	color: #57513f;
	font-size: rem-calc(18);
	font-weight: 700;
	margin: rem-calc(0 0 20 0);
	li {
		list-style: none;
		background: url('/images/icon-leaves.png') center left no-repeat;
		padding: rem-calc(5 0 5 28);
	}
}
.quantity {
	font-size: rem-calc(24);
	padding-top: rem-calc(20);
	color: #5b5b5b;
	input {
		display: inline-block;
		width: rem-calc(80);
		background: #fefefe;
		border: 0;
		box-shadow: none;
		font-size: rem-calc(24);
		color: #5b5b5b;
		&:hover {
			cursor: pointer;
		}
	}
}
.product form button.submit {
	font-family: $special-font-family;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
	font-size: rem-calc(18);
	font-weight: 400;
	img {
		margin-left: rem-calc(4);
		margin-top: rem-calc(-2);
	}
}
.us-only {
	display: inline-block;
	margin-left: rem-calc(10);
	color: #990000;
	font-weight: 700;
}

.special-order {
	color: red;
}

.product-variations {
	background: #fffaef;
	border: 1px solid #c1c1c1;
	border-bottom: 0;
	.variation {
		padding: rem-calc(20);
		border-bottom: 1px solid #c1c1c1;
		transition: .1s;
		&:hover {
			background: darken(#fffaef, 10%);
		}
		input {
			float: left;
			display: inline-block;
			margin-top: rem-calc(4);
			margin-right: rem-calc(8);
		}
		.variation-name {
			font-size: rem-calc(18);
			font-weight: 700;
			font-family: $special-font-family;
			color: #000;
			line-height: 1.2;
		}
		.variation-number {
			font-size: rem-calc(18);
			font-weight: 300;
			font-family: $special-font-family;
			color: #505050;
			line-height: 1.2;
			margin-left: rem-calc(20);
		}
		.variation-price {
			color: #84c042;
			font-size: rem-calc(24);
			font-weight: 700;
			font-family: $special-font-family;
			margin-left: rem-calc(20);
		}
		@media #{$medium-up}{
			.variation-price {
				margin-top: rem-calc(-27);
				display: inline-block;
				float: right;
			}
		}
	}
}
