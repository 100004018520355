table {
	border-spacing: 1px;
	border-collapse:initial;
}

#UserAccountLogin {
	table {
		background: transparent;
		border: 0;
		input {
			margin: 0 auto rem-calc(10);
			display: block;
		}
		tr.even, tr.alt, tr:nth-of-type(even) {
			background: transparent;
		}
		tr td {
			color: #333;
			padding: rem-calc(0 0 12);
		}
	    tr:nth-child(3) td {
			text-align: left;
	    }
	}
	table tr:nth-child(4) td , table tr:last-child td {
		padding: rem-calc(0 0);
	}
}

#FormSelectStateContainer {
	span {
		@include grid-column(
			$columns: 3,
			$collapse: true
		);
		padding-left: rem-calc(10);
		line-height: rem-calc(36);
	}
	select, input {
		@include grid-column(
			$columns: 9
		);
		margin-bottom: rem-calc(10);
	}
	@media #{$medium-up}{
		span {
			@include grid-column(
				$columns: 2,
				$collapse: true
			);
			padding-left: rem-calc(10);
			line-height: rem-calc(36);
		}
		select, input {
			@include grid-column(
				$columns: 4
			);
		}
	}
	@media #{$large-up}{
		span {
			@include grid-column(
				$columns: 1,
				$collapse: true
			);
			padding-left: rem-calc(10);
			line-height: rem-calc(36);
		}
		select, input {
			@include grid-column(
				$columns: 5
			);
		}
	}
}

form {
	.fm-question {
		font-weight: 600;
		font-size: rem-calc(18);
	}
	input.form_text.fm-zip[type="text"], input.form_text.fm-zipext[type="text"] {
		width: rem-calc(100);
		display: inline-block;
	}
	input.form_text.fm-zip[type="text"] {
		margin-right: rem-calc(5);
	}
	input.form_text.fm-zipext[type="text"] {
		margin-left: rem-calc(5);
	}
}

.form-container {
	h3 {
		text-align: center;
		margin-bottom: rem-calc(15);
	}

	#UserAccountLogin {
		table {
			background: transparent;
			border: 0;
			input {
				margin-bottom: 0;
			}
			tr.even, tr.alt, tr:nth-of-type(even) {
				background: transparent;
			}
			tr td {
				color: #fff;
				padding: rem-calc(0 0 12);
			}
		    tr:nth-child(3) td {
				text-align: left;
		    }
		}
		table tr:nth-child(4) td , table tr:last-child td {
			padding: rem-calc(0 0);
		}
	}
}

.chk_silver .form_cd_question_container {
	border: 0;
}

.helpinstructions {
	padding: 0;
	table {
		margin: 0;
		border: 0;
	}
}

.forgot_login_link {
	display: block;
	text-align: center;
	margin-bottom: rem-calc(20);
	font-size: rem-calc(16);
}

.cart_discount_container {
	border: 0;
	background: transparent;
}

.shoppingcart_checkout_subheader {
	clear: both;
}

.shipping-header {
	margin-top: rem-calc(30);
}

.billing-address {
	margin-bottom: rem-calc(20);
	address {
		font-style: normal;
	}
}

.change-password, .change-email, .retrieve-email, .change-email table {
	border: 0;
	td {
		padding: 0;
	}
	tr:nth-of-type(even) {
		background: transparent;
	}
}

#ImageAuthorizationTable img {
	max-width: initial;
}

#RegisteredEmail {
	display: inline-block;
	margin-bottom: rem-calc(20);
	background: #fff;
	color: #000;
	border: 1px solid #ccc;
}

.actiontable {
	table {
		border: 0;
		tr:nth-of-type(even) {
			background: transparent;
		}
	}
}

.retrieve-email .actiontable {
	tr td {
		padding-top: 0;
	}
}

.cart_table {
	margin: rem-calc(30 0);
	th {
		background: #fff;
	}
	a {
		color: darken($secondary-color, 10%);
	}
}

.cart_discount_container {
	input[type="submit"].buttons {
		padding: rem-calc(6 18);
		// background-color: #8c8c8c;
		margin-top: rem-calc(4);
		&:hover {
			color: #fff;
		}
	}
	input[type="text"].form_text {
		background-color: #fff;
		border-radius: 0;
		border: 1px solid #ccc;
	}
}

.shoppingcart_checkout_subheader {
	clear: both;
}

.shipping_estimator_container h3 {
	margin-bottom: 0;
}

// Checkout Page

#page-id-8 {
	h1 {
		padding-bottom: 0;
		text-align: center;
	}
	.logout-options {
		text-align: center;
	}
	.account-questions {
		@include grid-column(12);
		margin-top: rem-calc(30);
		@media #{$large-up}{
			@include grid-column(
				$columns:8,
				$center:true
			);
		}
	}
}
.logintable {
	text-align: center;
	margin: 0 auto;
}
.logintable_header {
	text-align: center;
	margin-bottom: rem-calc(10);
}

#page-id-8 .content form, .user-registration-form form {
	.alert a {
		color: darken($secondary-color, 10%);
	}
	.fm-answer {
		@include clearfix;
	}
	.form-create-new-account-message{
		color: $secondary-color;
		font-weight: 700;
		font-size: rem-calc(18);
	}
	.form-create-new-account-box {
		margin: rem-calc(10 0);
	}
	input[type="text"].form_text,
	input[type="email"].form_text,
	textarea.form_textarea,
	input[type="password"],
	select {
		background-color: #fff;
		color: #000;
		border: 1px solid #ccc;
		margin-bottom: 0;
	}
	.fm-question-container {
		margin-bottom: rem-calc(8);
	}
	.fm-qid-13 {
		margin-bottom: rem-calc(30);
	}
	.fm-qid-30 .fm-question {
		padding: .66667rem .83333rem;
		margin-bottom: rem-calc(15);
		border: 1px solid #ccc;
		color: #fff;
		background-color: $primary-color;
		line-height: 1.4;
		font-family: $header-font-family;
		font-size: rem-calc(20);
	}
	.chk_silver {
		border: 0;
		background: 0;
	}
	.chk_silver .form_text {
		padding: rem-calc(4 8);
	}
	::-webkit-input-placeholder {
		color: #444;
		opacity:1;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #444;
		opacity:1;
	}
	::-moz-placeholder {  /* Firefox 19+ */
		color: #444;
		opacity:1;
	}
	:-ms-input-placeholder {
		color: #444;
		opacity:1;
	}
	select.form_select[data-selected=""]{
		color: #444;
		opacity:1;
	}
	.form-required-div {
		color: #000;
		text-align: left
	}
	.required.columns {
		text-align: center;
		padding-bottom: rem-calc(10);
	}
	.fm-addressline2 {
		margin: rem-calc(6 0 0);
	}
	.fm-addressline3 {
		display: none;
	}
	.form_submit_container {
		clear: both;
	}
	.button {
		margin: rem-calc(10 0 0);
	}
}

// Shipping table
#ShippingOptionsContents {
	padding-top: rem-calc(10);
}
#SubmitShippingMethod, .buttons_checkout {
	@extend button;
	font-family: $special-font-family;
	text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
	font-size: rem-calc(18);
	font-weight: 400;
}

// Submit Payment 
#CreditProcessDialogue {
	input[type="text"] {
		margin-bottom: 0;
		& + a {
			margin-top: rem-calc(10);
			display: inline-block;
		}
	}
	label {
		display: inline;
	}
}

#PayFor_ExpirationDateMonth, #PayFor_ExpirationDateYear {
	max-width: rem-calc(80);
	margin-left: rem-calc(4);
}

#PayFor_VerificationNumber {
	max-width: rem-calc(80);
	display: inline-block;
	margin-right: rem-calc(20);
}

.chk_lightorange {
	border: 0;
	background: transparent;
	input[type="checkbox"] {
		margin: 0 0 3px 0;
	}
}

.logintable_header {
	text-align: center;
}

.billing-address {
	margin-bottom: rem-calc(20);
	address {
		font-style: normal;
	}
}

.agreement {
	background: $primary-color;
	padding: rem-calc(8);
	margin-bottom: rem-calc(10);
	color: #fff;
	input {
		margin: rem-calc(0 4 1);
	}
}

.confirmpaymentbutton_container {
	margin-bottom: rem-calc(30);
}